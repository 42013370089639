.App {
  text-align: center;
  background-color: #000;
  color: #fff;
}

.App a {
  color: #4af626;
  text-decoration: none;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.App-header pre.asciiArt {
  color: #4af626;
  font-size: 0.5em;
}

.App-header pre.wtfaq {
  font-size: 0.5em;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: left;
  line-height: 1.5em;
}

.App-header ul {
  text-align: left;
  font-size: 1em;
  border: thin dotted #fff;
  padding: 0.5em 2em;
}

.App-header-links {
  display: inline-block;
  font-size: 0.75em;
}

.project-info {
  font-size: 0.75em;
  font-weight: 300;
}

.project-info a {
  text-decoration: none;
  font-size: 1em;
}
